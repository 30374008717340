import { Trans } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import BgImageDesktop15x from "../assets/images/header-room/desktop-1.5x.jpeg";
import BgImageDesktop1x from "../assets/images/header-room/desktop-1x.jpeg";
import BgImageDesktop2x from "../assets/images/header-room/desktop-2x.jpeg";
import BgImageMobileBig15x from "../assets/images/header-room/mobile-big-1.5x.jpeg";
import BgImageMobileBig1x from "../assets/images/header-room/mobile-big-1x.jpeg";
import BgImageMobileBig2x from "../assets/images/header-room/mobile-big-2x.jpeg";
import BgImageMobileSmall15x from "../assets/images/header-room/mobile-small-1.5x.jpeg";
import BgImageMobileSmall1x from "../assets/images/header-room/mobile-small-1x.jpeg";
import BgImageMobileSmall2x from "../assets/images/header-room/mobile-small-2x.jpeg";
import { Button, ContentWrapper, H1Css } from "../assets/styles/base";
import StarsBig from "../assets/svg/starsWithLine.svg";
import { breakpoints } from "../constants/breakpoints";
import { colors } from "../constants/colors";
import { urls, extendUrlWithSourceVersion } from "../constants/urls";

const HomeImageHeader = ({ title, logo }) => {
    return (
        <Wrapper>
            <LeftCol>
                <UpperContentWrapper>
                    {logo ? (
                        <LogoWrapper>
                            <LogoImage {...logo} />
                        </LogoWrapper>
                    ) : null}
                    <Title>{title ? title : <Trans>homeHeader.title</Trans>}</Title>
                </UpperContentWrapper>
                <StarsBigBg />
                <BottomContentWrapper>
                    <Box>
                        <BulletsWrapper>
                            <h3>Ein paar Klicks entfernt.</h3>
                            <ul>
                                <li>Kostenloser Konditionenvergleich</li>
                                <li>Bessere Konditionen von verschiedenen Banken</li>
                                <li>Abwicklung mit deiner Wunschbank</li>
                            </ul>
                        </BulletsWrapper>
                        <a href={extendUrlWithSourceVersion(urls.survey)}>
                            <Button>
                                <Trans>Kreditvergleich starten</Trans>
                            </Button>
                        </a>
                    </Box>
                </BottomContentWrapper>
            </LeftCol>
            <RightCol hasLogo={!!logo} />
        </Wrapper>
    );
};

const smallVersion = "919px";
const bigVersion = "920px";

const LogoWrapper = styled.div`
    margin-bottom: 1.5rem;
    @media (max-width: ${smallVersion}) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
`;
const LogoImage = styled.img`
    max-height: 90px;
    max-width: 150px;
    height: auto;
    @media (max-width: ${smallVersion}) {
        max-width: 220px;
        margin-top: 65px;
        width: auto;
        max-width: none;
    }
`;
const Wrapper = styled.div`
    position: relative;
    background-color: #f6f8f7;
    display: flex;

    @media (max-width: ${smallVersion}) {
        flex-direction: column-reverse;
    }
`;

const LeftCol = styled.div`
    box-sizing: border-box;
    width: 48%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;

    @media (max-width: ${smallVersion}) {
        margin: 0 auto;
        max-width: 460px;
        width: 100%;
        padding: 10px 0;
        padding: 0 30px;
    }
`;
const RightCol = styled.div`
    box-sizing: border-box;
    margin-left: 4%;
    margin-right: 30px;
    width: 48%;

    background-image: url(${BgImageDesktop1x});
    background-image: -webkit-image-set(
        url(${BgImageDesktop1x}) 1x,
        url(${BgImageDesktop15x}) 1.5x,
        url(${BgImageDesktop2x}) 2x
    );
    background-image: image-set(
        url(${BgImageDesktop1x}) 1x,
        url(${BgImageDesktop15x}) 1.5x,
        url(${BgImageDesktop2x}) 2x
    );

    background-size: 640px 640px;
    background-repeat: no-repeat;
    background-position: 35% 72%;

    @media (min-width: ${breakpoints.desktopMin}) {
        background-position: center 72%;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin-right: 60px;
    }

    @media (max-width: ${smallVersion}) {
        opacity: ${({ hasLogo }) => (hasLogo ? 0.65 : 1)};
        background-image: url(${BgImageMobileBig1x});
        background-image: -webkit-image-set(
            url(${BgImageMobileBig1x}) 1x,
            url(${BgImageMobileBig15x}) 1.5x,
            url(${BgImageMobileBig2x}) 2x
        );
        background-image: image-set(
            url(${BgImageMobileBig1x}) 1x,
            url(${BgImageMobileBig15x}) 1.5x,
            url(${BgImageMobileBig2x}) 2x
        );

        background-position: center center;
        background-size: 919px 220px;
        height: 220px;
        margin: 0;
        width: 100%;
    }

    @media (max-width: 420px) {
        background-image: url(${BgImageMobileSmall1x});
        background-image: -webkit-image-set(
            url(${BgImageMobileSmall1x}) 1x,
            url(${BgImageMobileSmall15x}) 1.5x,
            url(${BgImageMobileSmall2x}) 2x
        );
        background-image: image-set(
            url(${BgImageMobileSmall1x}) 1x,
            url(${BgImageMobileSmall15x}) 1.5x,
            url(${BgImageMobileSmall2x}) 2x
        );

        background-position: center center;
        background-size: 440px 220px;
        height: 220px;
        margin: 0;
        width: 100%;
    }
`;

const UpperContentWrapper = styled.div`
    box-sizing: border-box;
    margin: 0 0 18px auto;
    max-width: 636px;
    width: 100%;
    z-index: 1;

    @media (min-width: ${breakpoints.tabletBigMin}) {
        padding-left: 60px;
    }

    @media (max-width: ${smallVersion}) {
        padding-left: 0;
        padding-top: 25px;
    }

    @media (min-width: ${bigVersion}) {
        margin-bottom: 40px;
    }
`;
const StarsBigBg = styled.div`
    @media (min-width: ${bigVersion}) {
        position: relative;
        background-image: url(${StarsBig});
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 125%;
        height: 150px;
        margin: -80px -25% -80px 0;
    }
    @media (min-width: 1260px) {
        width: 100%;
        margin: -80px 0 -80px 0;
    }
`;

const BottomContentWrapper = styled(ContentWrapper)`
    box-sizing: border-box;
    margin: 0 0 40px auto;
    max-width: 636px;
    padding: 0 0 0 30px;
    width: 100%;

    @media (max-width: ${smallVersion}) {
        padding-left: 0;
    }
    @media (min-width: ${bigVersion}) {
        padding-left: 60px;
    }
`;

const Title = styled.h1`
    ${H1Css};
    margin: 0;
`;

const BulletsWrapper = styled.div`
    font-size: 12px;
    text-align: left;

    h3 {
        font-size: 14px;
        font-weight: 300;
        margin: 0 0 10px;
    }
    ul {
        list-style: none;
        margin: 14px 0px 24px;
        padding: 0;
    }
    li {
        margin: 0 0 8px;
        padding-left: 15px;
        position: relative;

        :before {
            background: ${colors.primary};
            border-radius: 50%;
            box-sizing: border-box;
            color: #fff;
            content: "✓";
            font-size: 9px;
            height: 11px;
            left: -1px;
            line-height: 1.4;
            position: absolute;
            text-align: center;
            top: 2px;
            width: 11px;
        }
    }

    @media (min-width: ${bigVersion}) {
        font-size: 15px;

        h3 {
            font-size: 18px;
        }
        li {
            padding-left: 20px;

            :before {
                font-size: 12px;
                height: 15px;
                padding-left: 1px;
                top: 3px;
                width: 15px;
            }
        }
    }
`;

const Box = styled.div`
    position: relative;
    text-align: center;

    @media (min-width: ${bigVersion}) {
        background: #fff;
        border-radius: 25px;
        box-shadow: 0px 0px 30px #00000003;
        padding: 24px 25px;
        text-align: left;
        width: 330px;
    }
`;

export default HomeImageHeader;
