import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Cooperation from "../components/Cooperation";
import FantasticNumbersBar from "../components/FantasticNumbersBar";
import Faq from "../components/Faq";
import HomeHeader from "../components/HomeImageHeader1CTA";
import InfoBoxes from "../components/HomeInfoBoxes";
import Layout from "../components/Layout";
import MortgageCalculator from "../components/MortgageCalculator";
import ProcessExplanationShort from "../components/ProcessExplanationShort";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import MetaComponents from "../components/seo/MetaComponents";

const Media = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <HomeHeader />
            <MortgageCalculator hasMarginTop defaultTerm={35} defaultMortgage={320000} />
            <Cooperation hasMarginTop />
            <InfoBoxes hasGreyBg hasMarginTop />
            <ProcessExplanationShort hasMarginTop />
            <FantasticNumbersBar hasGreyBg hasMarginTop />
            <Faq ctaText="Mehr Antworten" ctaUrl="/faq" hasMarginTop />
            <BreadcrumbList page={"media"}></BreadcrumbList>
        </Layout>
    );
};

export default Media;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "homeHeader", "mortgageCalc", "page.index", "indexFaq"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
