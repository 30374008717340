import React from "react";
import styled from "styled-components";

import { ContentWrapper, GreyBg } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";

const FantasticNumbersBar = ({ hasGreyBg, hasMarginTop }) => {
    const Content = (
        <StyledContentWrapper hasMarginTop={hasMarginTop}>
            <h2>Zahlen, die für sich selbst sprechen</h2>
            <ul>
                <li>
                    <Number>Alle</Number>
                    <Type>Top-Banken</Type>
                </li>
                <li>
                    <Number>25+</Number>
                    <Type>Motivierte Mitarbeiter</Type>
                </li>
                <li>
                    <Number>1+ Mrd €</Number>
                    <Type>Finanzierungsvolumen angefragt</Type>
                </li>
            </ul>
        </StyledContentWrapper>
    );
    if (hasGreyBg) return <GreyBg>{Content}</GreyBg>;
    return Content;
};

const StyledContentWrapper = styled(ContentWrapper)`
    text-align: center;

    ul {
        list-style: none;
        margin: 45px auto 0;
        padding: 0;
    }

    li {
        margin-top: 27px;
        padding-top: 25px;
        position: relative;

        &:after {
            background: #c2d1d9;
            content: "";
            height: 1px;
            left: 50%;
            margin-left: -93px;
            position: absolute;
            top: 0;
            width: 186px;
        }

        &:first-child {
            padding-top: 15px;

            &:after {
                display: none;
            }
        }
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        ul {
            display: flex;
            justify-content: center;
        }
        li {
            border-left: 1px solid #c2d1d9;
            margin: 0 0 0 40px;
            padding: 18px 0 18px 39px;

            &:after {
                display: none;
            }

            &:first-child {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
            }
        }

        @media (min-width: ${breakpoints.desktopMin}) {
            align-items: center;
            display: flex;

            h2 {
                margin: 0 36px 0 0;
                text-align: left;
                width: 330px;
            }

            ul {
                margin: 0 auto;
            }
        }
    }
`;

const Number = styled.span`
    color: #282828;
    display: block;
    font-size: 50px;
    font-weight: 600;
    line-height: 1em;
    margin-bottom: 8px;
    white-space: nowrap;
`;

const Type = styled.span`
    display: block;
    color: #576776;
    font-size: 14px;
    line-height: 1em;
    white-space: nowrap;
`;

export default FantasticNumbersBar;
